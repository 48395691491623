// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ResourceEdit from "./ResourceEdit.res.js";
import * as ResourceItem from "../../../models/ResourceItem.res.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceCategoryAsFilter from "../../../models/ResourceCategoryAsFilter.res.js";

function ResourceEdit__JsBridge$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ResourceEdit.make, {
              resource: ResourceItem.fromJs(props$1.resource),
              categories: Belt_SortArray.stableSortBy(Belt_Array.map(props$1.categories, ResourceCategoryAsFilter.fromJs), (function (x1, x2) {
                      return Caml.string_compare(x1.title, x2.title);
                    }))
            });
}

var $$default = ResourceEdit__JsBridge$default;

export {
  $$default as default,
}
/* ResourceEdit Not a pure module */
